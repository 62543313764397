.custom_404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 1rem;
  background-color: #f2f2f2;

  .heading {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }

  .message {
    font-size: 20px;
    text-align: center;
    max-width: 500px;
    margin-bottom: 40px;
  }
}
